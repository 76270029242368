<script setup>
import {useBaseStore} from "~/stores/base";

const emits = defineEmits(["update:modelValue", "input", "focus", "blur", "confirm", "clear"])

/** props **/
const props = defineProps({
  title: {
    default: ""
  },
  type: {
    default: "text" // password text ...
  },
  modelValue: {
    type: [String, Number],
    default: "",
    required: true
  },
  placeholder: {
    default: ""
  },
  maxLength: {
    type: [String, Number],
    default: 999
  },
  prefixIcon: {
    default: "",
  },
  suffixButton: {
    default: false
  },
  suffixButtonText: {
    default: "button"
  },
  suffixButtonDisabled: {
    default: false
  },
  name: {
    default: ""
  },
  tips: {
    default: ""
  },
  errorTips: {
    default: ""
  },
  error: {
    default: false
  },
  successTips: {
    default: ""
  },
  success: {
    default: false
  },
  inputClass: {
    default: ""
  },
  containerClass: {
    default: ""
  },
  clearable: {
    default: false
  },
  size: {
    default: 'normal' // "large"
  },
  disabled: {
    default: false
  }
})
/** data **/
const focused = ref(false)
const isShowPassword = ref(false)
// data type code
const codeInput = ref(null)
const codeInputFocused = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

/** methods **/
const handleFocus = (e) => {
  focused.value = true
  emits("focus", e)
}
const handleBlur = (e) => {
  focused.value = false
  emits("blur", e)
}
const updateValue = (newValue) => {
  // 触发 update:modelValue 事件来更新父组件中的值
  emits("update:modelValue", newValue)
  emits("input", newValue)
}
const eyeToggle = () => {
  isShowPassword.value = !isShowPassword.value
}
// type code 手动聚焦
const handleManualFocus = () => {
  if (codeInput.value) codeInput.value.focus()
}
const handleClear = () => {
  emits("update:modelValue", "")
  emits("clear")
}
const handleConfirm = () => {
  if (props.suffixButtonDisabled) return
  emits("confirm")
}

/** lifecycle **/
onMounted(() => {
  if (!codeInput.value) return
  /** 监听聚焦 **/
  codeInput.value.addEventListener('focus', () => {
    console.log("focus")
    codeInputFocused.value = true
  })
  /** 监听失焦 **/
  codeInput.value.addEventListener('blur', () => {
    console.log("blur")
    codeInputFocused.value = false
  })
  /** 自动聚焦 **/
  setTimeout(() => {
    handleManualFocus()
  }, 100)
})
</script>

<template>
  <div class="module-input-mobile" :class="`${containerClass}`" v-if="isMobile">
    <div class="input-title" v-if="title">{{ title }}</div>
    <!-- code input -->
    <div v-if="type === 'code'" class="input-wrapper-type-code">
      <input
        :aria-label="title"
        type="tel"
        ref="codeInput"
        :value="modelValue"
        maxlength="6"
        @input="updateValue($event.target.value)"
        @focus="handleFocus($event)"
        @blur="handleBlur($event)"
      >
      <div class="code-input-boxes" @click="handleManualFocus">
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 0, error: error, success: success}">
          {{ modelValue.charAt(0) }}
        </div>
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 1, error: error, success: success}">
          {{ modelValue.charAt(1) }}
        </div>
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 2, error: error, success: success}">
          {{ modelValue.charAt(2) }}
        </div>
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 3, error: error, success: success}">
          {{ modelValue.charAt(3) }}
        </div>
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 4, error: error, success: success}">
          {{ modelValue.charAt(4) }}
        </div>
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 5, error: error, success: success}">
          {{ modelValue.charAt(5) }}
        </div>
      </div>
    </div>
    <!-- 正常input -->
    <div
      v-else
      class="input-wrapper-container"
      :class="`${inputClass} ${focused ? 'input-focused' : ''} ${error ? 'input-error' : ''} ${success ? 'input-success' : ''} ${disabled ? 'input-disabled' : ''}`"
    >
      <div class="input-wrapper" :class="{'size-large': size === 'large'}">
        <img v-if="prefixIcon" class="prefix-icon" :src="prefixIcon" alt="prefix-icon">
        <input
          :aria-label="title"
          :class="`${disabled ? 'disabled' : ''}`"
          :type="isShowPassword ? 'text' : type"
          :value="modelValue"
          :placeholder="placeholder"
          :name="name"
          :maxLength="maxLength"
          :disabled="disabled"
          @input="updateValue($event.target.value)"
          @focus="handleFocus($event)"
          @blur="handleBlur($event)"
        >
        <!-- 叹号 -->
        <div class="error-alert" v-if="error">
          <i class="iconfont icon-exclamation-circle"></i>
        </div>
        <!-- 眼睛 -->
        <div class="eye" v-if="modelValue && type === 'password'" @click="eyeToggle">
          <i class="iconfont icon-browse" v-if="isShowPassword"/>
          <i class="iconfont icon-eye-close" v-else/>
        </div>
        <!-- 清空 -->
        <div class="clearable" v-if="clearable && modelValue" @click="handleClear">
          <img src="@/assets/img/icon-close-black.svg" alt="clear">
        </div>
        <!-- 对钩 -->
        <div class="success-check" v-if="success">
          <i class="iconfont icon-check-circle"></i>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="btn-secondary suffix-button" :class="{'btn-disabled-secondary': suffixButtonDisabled}" v-if="suffixButton" @click="handleConfirm">
        <span>{{ suffixButtonText }}</span>
      </div>
    </div>
    <div class="input-tips" v-if="!error && tips">
      {{ tips }}
    </div>
    <div class="input-error-tips" v-html="errorTips" v-if="error"/>
    <div class="input-success-tips" v-html="successTips" v-if="success"/>
  </div>
  <div class="module-input-desktop" :class="`${containerClass}`" v-else>
    <div class="input-title" v-if="title">{{ title }}</div>
    <!-- code input -->
    <div v-if="type === 'code'" class="input-wrapper-type-code">
      <input
        :aria-label="title"
        type="tel"
        ref="codeInput"
        :value="modelValue"
        maxlength="6"
        @input="updateValue($event.target.value)"
        @focus="handleFocus($event)"
        @blur="handleBlur($event)"
      >
      <div class="code-input-boxes" @click="handleManualFocus">
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 0, error: error, success: success}">
          {{ modelValue.charAt(0) }}
        </div>
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 1, error: error, success: success}">
          {{ modelValue.charAt(1) }}
        </div>
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 2, error: error, success: success}">
          {{ modelValue.charAt(2) }}
        </div>
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 3, error: error, success: success}">
          {{ modelValue.charAt(3) }}
        </div>
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 4, error: error, success: success}">
          {{ modelValue.charAt(4) }}
        </div>
        <div class="code-input-box"
             :class="{focused: codeInputFocused && modelValue.length === 5, error: error, success: success}">
          {{ modelValue.charAt(5) }}
        </div>
      </div>
    </div>
    <!-- 正常input -->
    <div
      v-else
      class="input-wrapper-container"
      :class="`${inputClass} ${focused ? 'input-focused' : ''} ${error ? 'input-error' : ''} ${success ? 'input-success' : ''} ${disabled ? 'input-disabled' : ''}`"
    >
      <div class="input-wrapper" :class="{'size-large': size === 'large'}">
        <img v-if="prefixIcon" class="prefix-icon" :src="prefixIcon" alt="prefix-icon">
        <input
          :aria-label="title"
          :class="`${disabled ? 'disabled' : ''}`"
          :type="isShowPassword ? 'text' : type"
          :value="modelValue"
          :placeholder="placeholder"
          :name="name"
          :maxLength="maxLength"
          :disabled="disabled"
          @input="updateValue($event.target.value)"
          @focus="handleFocus($event)"
          @blur="handleBlur($event)"
        >
        <!-- 叹号 -->
        <div class="error-alert" v-if="error">
          <i class="iconfont icon-exclamation-circle"></i>
        </div>
        <!-- 眼睛 -->
        <div class="eye" v-if="modelValue && type === 'password'" @click="eyeToggle">
          <i class="iconfont icon-browse" v-if="isShowPassword"/>
          <i class="iconfont icon-eye-close" v-else/>
        </div>
        <!-- 清空 -->
        <div class="clearable" v-if="clearable && modelValue" @click="handleClear">
          <img src="@/assets/img/icon-close-black.svg" alt="clear">
        </div>
        <!-- 对钩 -->
        <div class="success-check" v-if="success">
          <i class="iconfont icon-check-circle"></i>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="btn-secondary suffix-button" :class="{'btn-disabled-secondary': suffixButtonDisabled}" v-if="suffixButton" @click="handleConfirm">
        <span>{{ suffixButtonText }}</span>
      </div>
    </div>
    <div class="input-tips" v-if="!error && tips">
      {{ tips }}
    </div>
    <div class="input-error-tips" v-html="errorTips" v-if="error"/>
    <div class="input-success-tips" v-html="successTips" v-if="success"/>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-input-mobile {
  width: 100%;

  .input-title {
    //styleName: Text sm/Bold;
    font-family: "TWK Lausanne";
    font-weight: 650;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 6px;
  }

  .input-tips {
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    color: $color-gray-800;
    margin-top: 6px;
  }

  .input-error-tips {
    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    color: $color-BNTO-red-alert;
    margin-top: 6px;
  }

  .input-success-tips {
    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    color: $color-BNTO-green;
    margin-top: 6px;
  }

  .input-wrapper-type-code {
    position: relative;
    background: white;

    input {
      position: absolute;
      bottom: 0;
      z-index: -1;
      opacity: 0;
    }

    .code-input-boxes {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;

      .code-input-box {
        flex-shrink: 0;
        width: 49px;
        height: 36px;
        box-shadow: 0 0 0 0.5px $color-gray-300;
        display: flex;
        justify-content: center;
        align-items: center;

        //styleName: Text sm/Regular;
        font-family: TWK Lausanne;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: center;
        cursor: text;
      }

      .focused {
        box-shadow: 0 0 0 0.5px $color-gray-700;
        position: relative;

        &::after {
          content: "";
          width: 50px;
          height: 37px;
          border: 4px $color-gray-200 solid;
          position: absolute;
          left: -4.5px;
          top: -4.5px;
        }
      }

      .error {
        box-shadow: 0 0 0 0.5px $color-BNTO-red-alert;
      }

      .success {
        box-shadow: 0 0 0 0.5px $color-BNTO-green;
      }
    }
  }

  .input-wrapper-container {
    width: 100%;
    display: flex;
    box-shadow: 0 0 0 0.5px $color-gray-300;
    transition: all .2s linear;

    .input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px 12px;

      .prefix-icon {
        height: 20px;
        margin-right: 8px;
      }

      input {
        border: none;
        outline: none;
        color: $color-gray-800;

        width: 100%;
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        background-color: transparent;

        &.disabled {
          color: $color-gray-500;
          cursor: not-allowed;
        }

        &::placeholder {
          color: $color-gray-500;
        }
      }

      .success-check {
        color: $color-BNTO-green;

        display: flex;
        align-items: center;
        margin-left: 8px;
      }

      .error-alert {
        color: $color-BNTO-red-alert;

        display: flex;
        align-items: center;
        margin-left: 8px;
      }

      .eye {
        margin-left: 8px;

        display: flex;
        align-items: center;

        cursor: pointer;
      }

      .clearable {
        display: flex;
        cursor: pointer;
      }
    }

    .size-large {
      padding: 9px 12px;

      input {
        font-family: "TWK Lausanne";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 150% */
      }
    }

    .suffix-button {
      flex-shrink: 0;

      /* Button/small */
      font-family: "Druk Wide Cy";
      font-size: 9px;
      font-style: normal;
      font-weight: 500;
      line-height: 10px; /* 111.111% */
      text-transform: uppercase;

      min-height: 36px;
      padding: 0 8px;
    }
  }

  .input-focused {
    box-shadow: 0 0 0 1.5px $color-BNTO-beige-dark;
  }

  .input-error {
    box-shadow: 0 0 0 0.5px $color-BNTO-red-alert;
  }

  .input-success {
    box-shadow: 0 0 0 0.5px $color-BNTO-green;
  }

  .input-disabled {
    box-shadow: 0 0 0 0.5px $color-gray-300;
    background: $color-gray-50;
    cursor: not-allowed;
  }
}

.module-input-desktop {
  width: 100%;

  .input-title {
    //styleName: Text sm/Bold;
    font-family: "TWK Lausanne";
    font-weight: 650;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    margin-bottom: 6px;
  }

  .input-tips {
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    color: $color-gray-800;
    margin-top: 6px;
  }

  .input-error-tips {
    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    color: $color-BNTO-red-alert;
    margin-top: 6px;
  }

  .input-success-tips {
    /* Text xs/Regular */
    font-family: "TWK Lausanne";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */

    color: $color-BNTO-green;
    margin-top: 6px;
  }

  .input-wrapper-type-code {
    position: relative;
    background: white;

    input {
      position: absolute;
      bottom: 0;
      z-index: -1;
      opacity: 0;
    }

    .code-input-boxes {
      margin-top: 6px;
      display: flex;
      justify-content: space-between;

      .code-input-box {
        flex-shrink: 0;
        width: 49px;
        height: 36px;
        box-shadow: 0 0 0 0.5px $color-gray-300;
        display: flex;
        justify-content: center;
        align-items: center;

        //styleName: Text sm/Regular;
        font-family: TWK Lausanne;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        text-align: center;
        cursor: text;
      }

      .focused {
        box-shadow: 0 0 0 0.5px $color-gray-700;
        position: relative;

        &::after {
          content: "";
          width: 50px;
          height: 37px;
          border: 4px $color-gray-200 solid;
          position: absolute;
          left: -4.5px;
          top: -4.5px;
        }
      }

      .error {
        box-shadow: 0 0 0 0.5px $color-BNTO-red-alert;
      }

      .success {
        box-shadow: 0 0 0 0.5px $color-BNTO-green;
      }
    }
  }

  .input-wrapper-container {
    width: 100%;
    display: flex;
    box-shadow: 0 0 0 0.5px $color-gray-300;
    transition: all .2s linear;

    .input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 8px 12px;

      .prefix-icon {
        height: 20px;
        margin-right: 8px;
      }

      input {
        border: none;
        outline: none;
        color: $color-gray-800;

        width: 100%;
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        text-align: left;
        background-color: transparent;

        &.disabled {
          color: $color-gray-500;
          cursor: not-allowed;
        }

        &::placeholder {
          color: $color-gray-500;
        }
      }

      .success-check {
        color: $color-BNTO-green;

        display: flex;
        align-items: center;
        margin-left: 8px;
      }

      .error-alert {
        color: $color-BNTO-red-alert;

        display: flex;
        align-items: center;
        margin-left: 8px;
      }

      .eye {
        margin-left: 8px;

        display: flex;
        align-items: center;

        cursor: pointer;
      }

      .clearable {
        display: flex;
        cursor: pointer;
      }
    }

    .size-large {
      padding: 9px 12px;

      input {
        font-family: "TWK Lausanne";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 150% */
      }
    }

    .suffix-button {
      flex-shrink: 0;

      /* Button/small */
      font-family: "Druk Wide Cy";
      font-size: 9px;
      font-style: normal;
      font-weight: 500;
      line-height: 10px; /* 111.111% */
      text-transform: uppercase;

      min-height: 36px;
      padding: 0 8px;
    }
  }

  .input-focused {
    box-shadow: 0 0 0 1.5px $color-BNTO-beige-dark;
  }

  .input-error {
    box-shadow: 0 0 0 0.5px $color-BNTO-red-alert;
  }

  .input-success {
    box-shadow: 0 0 0 0.5px $color-BNTO-green;
  }

  .input-disabled {
    box-shadow: 0 0 0 0.5px $color-gray-300;
    background: $color-gray-50;
    cursor: not-allowed;
  }
}
</style>
